import axios from 'axios';

export const API_BASE_URL = process.env.REACT_APP_BACKEND_URL;

export const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

export const endpoints = {
  users: '/',
  jobs: '/jobs',
  applications: '/applications',
  login: '/login',
  register: '/user',
  contact: '/email',
  initializePayment: '/payment/initialize',
  payments: '/payments',
  assessments: '/assessments',
  admin: {
    payments: '/admin/payments',
    users: '/admin/users',
    assessments: '/admin/assessments',
    stats: '/admin/stats'
  }
}; 